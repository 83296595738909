import { timeToMins, addTimes } from './time';
import Swal from 'sweetalert2';
import Events from './events';
import RendicontazioniService from "../../../../services/api/rendicontazioni.service";
import { IPermessiStudioEsami } from '../../../../helpers/interfaces/rendicontazione';

interface Rendicontazione {
    id: number,
    nome: string,
    tempo: string,
    tipo: string,
    note: string,
    parent: CommessaPadre | null
    smartworking: boolean;
}

interface CommessaPadre {
    id: number,
    nome: string
}

interface arrayCommesse {
    [key: string]: string
}

interface arrayGiorni {
    [key: number]: Rendicontazione[]
}

interface statoObject {
    code: string;
    name: string;
}

const $ = require('jquery');
declare let moment: any;
class Reportings extends Events {

    protected giorni: arrayGiorni;
    protected totaleOre: string;
    protected edited: boolean;
    protected person: any;
    protected lastday: number;
    protected firstday: number;
    protected stato: statoObject;
    protected attachments: Array<any>;
    private parent: any;
    selectedDay: { year: number; month: number; day: number; };
    currentDate: { year: string; month: string; };
    rendicontazione_id: string;
    private pageView: 'amministrazione' | 'dipendente';

    constructor() {
        super();
        this.giorni = [];
        this.totaleOre = '00:00';
        this.edited = false;
        this.rendicontazione_id = '';
        this.person = {
            name: '',
            id: ''
        };
        this.stato = {
            code: 'O',
            name: 'Aperto'
        }
        this.attachments = [];
        this.parent = null;
        this.currentDate = {
            year: '',
            month: ''
        }
        this.selectedDay = {
            year: 0,
            month: 0,
            day: 0
        }
        this.lastday = 0
        this.firstday = 0

        this.clickSubmitRendicontazione(this.editRendicontazione);
        this.changeMonth(this.getStatoModificaRendicontazione);
        this.pageView = 'amministrazione';
    }

    setParent = (parent: any): void => { this.parent = parent; }
    getParent = () => { return this.parent; }

    setRendicontazioneId = (id: string): void => { this.rendicontazione_id = id; }
    getRendicontazioneId = (): string => { return this.rendicontazione_id; }

    setCurrentDate = (year: string, month: string): void => {
        this.currentDate = {
            year,
            month
        }
    }
    getCurrentDate = (): { year: string, month: string } => { return this.currentDate; }
    setSelectedDay = (year: number, month: number, day: number): void => {
        this.selectedDay = {
            year,
            month,
            day
        };
    }
    getSelectedDay = () => { return this.selectDay; }

    setEdited = (edited: boolean): void => { this.edited = edited; }
    getEdited = (): boolean => { return this.edited; }

    setPerson = (id: number, name: string, personalEmail: string = '', companyEmail: string = '', phone: string = '', personStatus: string = 'E', permessiStudioEsami: IPermessiStudioEsami = {} as IPermessiStudioEsami): void => { this.person = { id, name, personalEmail, companyEmail, phone, personStatus, permessiStudioEsami }; }

    getPersonName = (): string => { return this.person.name; }
    getPersonId = (): string => { return this.person.id; }
    getPersonPersonalEmail = (): string => { return this.person.personalEmail }
    getPersonCompanyEmail = (): string => { return this.person.companyEmail }
    getPersonPhone = (): string => { return this.person.phone }
    getPersonStatus = (): string => { return this.person.personStatus }
    getPersonRole = (): string => {
        const userStr: any = localStorage.getItem("user");
        const user = JSON.parse(userStr);
        return user.roles[0].code;
    }
    getPersonPermessiStudioEsami = (): IPermessiStudioEsami => { return this.person.permessiStudioEsami }

    setFirstDay = (firstDay: number) => { this.firstday = firstDay }
    getFirstDay = () => { return this.firstday }
    setLastDay = (lastDay: number) => { this.lastday = lastDay }
    getLastDay = () => { return this.lastday }

    setStato = (stato: statoObject): void => {
        this.stato = stato
            ? { ...stato }
            : {
                code: 'O',
                name: 'Aperto'
            };
    }
    getStatoCode = (): string => { return this.stato.code; }

    setAttachements = (attachements: Array<any>) => { this.attachments = attachements; }
    getAttachements = (): Array<any> => { return this.attachments; }

    getPageView = () => { return this.pageView }
    setPageView = (value: 'amministrazione' | 'dipendente') => { this.pageView = value }

    render = (day: number, data: Rendicontazione, status?: string, index?: number): string => {
        const reportings = typeof this.giorni[day] !== 'undefined' ? Object.keys(this.giorni[day]).length - 1 : 0;
        if (!index) { index = reportings; }
        if (!status) status = this.getStatoCode();

        var base = `<div class="rendicontazione" day="${day}" index="${index}"> \
                        <div class="event d-block p-1 pl-2 pr-2 mb-1 rounded small bg-info text-white">`;
        if (this.pageView !== 'dipendente') {
            if (['C', 'A'].includes(status)) {
                base += `<a class="deleteParent custom-icon me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;
            }
        } else {
            if (['O', 'R'].includes(status)) {
                base += `<a class="deleteParent custom-icon me-1" data-id="${data}"><i style="font-size: 18px" class="fa fa-trash" aria-hidden="true"></i></a>`;
            }
        }

        if (data.parent) {
            base += '<span class="nome-rendicontazione-padre"><span class="nome-commessa-padre">Commessa Padre:</span> <span class="valore-commessa-padre">' + data.parent.nome + '</span></span><br/>';
        }

        switch (data.tipo) {
            case 'commessa':
                base += '<span class="nome-rendicontazione"><span class="nome-commessa">Commessa:</span> <span class="valore-commessa">' + data.nome + '<br/>Smart Working: ' + (data.smartworking ? 'Si' : 'No') + '</span></span><br/>';
                break;
            case 'causale':
                base += '<span class="nome-causale">Causale:</span> <span class="valore-causale">' + data.nome + '<br/>Smart Working: ' + (data.smartworking ? 'Si' : 'No') + '</span><br/>';
                break;
        }

        base += '<span class="nome-tempo">Tempo:</span> <span class="valore-tempo">' + data.tempo + '</span><br/>';

        if (typeof data.note !== 'undefined' && data.note.trim()) {
            base += '<p>Nota: <span class="nota-rendicontazione">' + data.note + '</span></p>';
        }

        //base += '<span class="dettaglio-rendicontazione hide">'+JSON.stringify(data)+'</span> \
        base += `</div> \
                    </div>`;
        return base;
    }

    clear = (): void => {
        this.giorni = [];
        $('.rendicontazione').remove();
    }

    load = (data: any, parent: any): void => {
        this.clear();
        //var data = JSON.parse(reportings);
        for (var d = 1; d <= 31; d++) {
            if ($('#giorno-' + d).length > 0) {
                if (typeof data['d' + d] !== 'undefined' && data['d' + d] !== 'null' && data['d' + d]) {
                    const rendicontazione = JSON.parse(data['d' + d]);
                    this.giorni[d] = [];
                    for (var key in rendicontazione) {
                        this.giorni[d].push(rendicontazione[key]);
                        $('#giorno-' + d).append(this.render(d, rendicontazione[key], data.stato.code, Number(key)));
                    };

                }
            }
        }
        this.setParent(parent);
        this.setRendicontazioneId(data.id);
        this.setPerson(data.idperson, data.person, data.personal_email, data.company_email, data.phone, data.personStatus, data.permessiStudioEsami);
        this.setStato(data.stato);
        this.setFirstDay(data.firstday);
        this.setLastDay(data.lastday);
        this.setAttachements(data.attachments);
        this.setCurrentDate(data.annonumber, data.mesenumber);
        this.deleteParent(this.removeDay, this.setStatoModificaRendicontazione);
        this.updateTotateRendicontazione();
    }

    /*observeOpenRendicontazione = () => {
        this.openRendicontazione(this.modalRendicontazione);
    }*/

    removeDay = (day: number, reporting: number): void => {
        delete this.giorni[day][reporting];
        this.giorni[day].splice(reporting, 1);
        this.regenerateDayIndex(day);
        this.updateTotateRendicontazione();
    }

    regenerateDayIndex = (day: number): void => {
        const elements = $('#d' + day + ' .rendicontazione');
        if (elements.length > 0) {
            elements.each(function (index: number, element: any) {
                $(element).attr('index', index);
            });
        }
    }

    updateTotateRendicontazione = (): void => {
        let array_commesse: arrayCommesse = {};
        let totale = '00:00';
        for (var d = 1; d <= 31; d++) {
            var totale_giorno = '00:00';

            if (typeof this.giorni[d] !== 'undefined' && this.giorni[d].length > 0) {
                const rendicontazioni = this.giorni[d];
                for (let r = 0; r < rendicontazioni.length; r++) {

                    const nome = rendicontazioni[r].nome.trim();
                    const tempo = rendicontazioni[r].tempo;

                    totale = addTimes(totale, tempo);
                    totale_giorno = addTimes(totale_giorno, tempo);

                    $('#totale-giorno-' + d).text(totale_giorno);

                    const totaleMinuti = timeToMins(totale_giorno);
                    if (totaleMinuti > (8 * 60) && !$('#totale-giorno-' + d).hasClass('straordinario')) {
                        $('#totale-giorno-' + d).addClass('straordinario');
                    } else {
                        $('#totale-giorno-' + d).removeClass('straordinario');
                    }

                    var nome_rendicontazione = nome;
                    if (typeof array_commesse[nome_rendicontazione] == 'undefined') { array_commesse[nome_rendicontazione] = '00:00'; }
                    array_commesse[nome_rendicontazione] = addTimes(array_commesse[nome_rendicontazione], tempo);
                }
            } else {
                $('#totale-giorno-' + d).text('');
            }
        }
        this.totaleOre = totale;


        // totatle ore per commessa
        $('#rendicontazione-totale-ore .rendicontazione').remove();
        if (array_commesse) {

            for (var key in array_commesse) {
                $('#rendicontazione-totale-ore tbody').prepend(`<tr class="rendicontazione">
                    <th>${key}</th>
                    <td>${array_commesse[key]}</td>
                </tr>`);
            }
        }

        $('#totale-ore').text(totale);
        let checkTotaleOre = this.checkTotaleOreMaggioreOreMese();
        switch (checkTotaleOre) {
            case -1:
                $("#totale-ore").attr('class', 'text-warning');
                break;
            default:
                $("#totale-ore").attr('class', 'text-success');
        }
    }

    checkTotaleOreStraordinario() {
        let totale_ore = this.totaleOre;
        let ore_mese = $('.ore_mese').text().trim() + ':00';

        let minutiTotale = timeToMins(totale_ore);
        let minutiOre = timeToMins(ore_mese);

        return minutiTotale - minutiOre !== 0 ? (minutiTotale - minutiOre) / 60 : 0
    }

    checkTotaleOreMaggioreOreMese = (): number => {
        let totale_ore = this.totaleOre;
        let ore_mese = $('.ore_mese').text().trim() + ':00';

        let minutiTotale = timeToMins(totale_ore);
        let minutiOre = timeToMins(ore_mese);

        return minutiTotale === minutiOre ? 0 : (minutiTotale > minutiOre ? 1 : -1);
    }

    getStatoModificaRendicontazione = (): number => {
        return $('input[name="edited"]').val().trim();
    }

    setStatoModificaRendicontazione = (stato: number): void => {
        $('input[name="edited"]').val(stato);
    }

    isFreeTime = (giorno: number, currentTime: any[]): boolean => {
        var exist = true;
        if ($('#giorno-' + giorno).length > 0) {
            var rendicontazioni = $('#giorno-' + giorno + ' .rendicontazione');
            if (rendicontazioni.length > 0) {
                rendicontazioni.each(function (index: number) {
                    var valore_inizio = $(rendicontazioni[index]).find('.valore-inizio').text().trim();
                    var valore_fine = $(rendicontazioni[index]).find('.valore-fine').text().trim();

                    var currentDate = moment().format("YYYY-MM-DD");
                    [valore_inizio, valore_fine].forEach(function (time) {
                        if (exist) {
                            exist = !moment(currentDate + ' ' + time, "YYYY-MM-DD h:mm").isBetween(currentDate + ' ' + currentTime[0], currentDate + ' ' + currentTime[1]);
                        }
                    });
                });
            }
        }
        return exist;
    }

    checkSubmitRendicontazione = (): void => {
        Swal.fire({
            title: 'Sei sicuro di voler confermare la rendicontazione ?',
            //text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Conferma',
            cancelButtonText: 'Annulla'
        }).then((result: any) => {
            if (result.isConfirmed) {
                //this.submitRendicontazione();
            }
        });
    }

    editRendicontazione = async (status: string) => {
        var data = new FormData();
        let commesse = [];
        let causali = [];

        for (var d = 1; d <= 31; d++) {
            if (typeof this.giorni[d] !== 'undefined') {
                const rendicontazioni = this.giorni[d];
                let dett: string[] = [];

                for (let r = 0; r < rendicontazioni.length; r++) {
                    switch (rendicontazioni[r].tipo) {
                        case 'commessa':
                            commesse.push(rendicontazioni[r].id);
                            break;
                        case 'causale':
                            causali.push(rendicontazioni[r].id);
                            break;
                    }
                    dett.push(JSON.stringify(rendicontazioni[r]));
                }
                data.append('d' + d, '[' + dett + ']');
            }
        }
        data.append('status', status);
        const join_commesse = commesse.filter((v, i, a) => a.indexOf(v) === i).join(',');
        const join_causali = causali.filter((v, i, a) => a.indexOf(v) === i).join(',');
        data.append('commesse', join_commesse);
        data.append('causali', join_causali);

        const currentDate: any = this.getCurrentDate();

        data.append('anno', currentDate.year);
        data.append('mese', currentDate.month);

        if ($('.attachment').length > 0) {
            $('.attachment').each(function (index: any, element: any) {
                if ($(element)[0].files.length > 0) {
                    data.append('attachment[]', $(element)[0].files[0]);
                }
            });
        }

        //var url = '/dipendente/rendicontazione';
        if (status === 'M') {
            var rendicontazione_id = this.getRendicontazioneId();
            data.append('rendicontazione_id', rendicontazione_id);
            return await RendicontazioniService.amministrazioneEditRendicontazione(rendicontazione_id, data);
        }

        return await RendicontazioniService.dipendenteEditRendicontazione(currentDate.year, currentDate.month, data);
    }

    newRendicontazione = (formData: any) => {
        let day = this.selectedDay.day;

        let addRendicontazione = (formData: any, eachDay?: number) => {
            if (eachDay) { day = eachDay; }
            const commessa = formData.commessa;
            const causale = formData.causale;

            const ore = parseInt(formData.ore.toString().trim()) || '00';

            const minuti = formData.minuti.toString().trim().padStart(2, '0') || '00';
            const note = formData.note ? formData.note.trim().replace(/(<([^>]+)>)/gi, "") : '';

            const smartworking = formData.smartworking ? true : false;
            //const parent_id = $('#modal-action select[name="commessa"] option:selected').attr('parent-id');
            //const parent_value = $('#modal-action select[name="commessa"] option:selected').attr('parent-value');

            const dataValue = typeof commessa !== 'undefined' ? commessa.trim() : causale.trim();
            const splitDataValue = dataValue.split('||');

            let data: Rendicontazione = {
                id: splitDataValue.length > 1 ? splitDataValue[0] : dataValue,
                nome: splitDataValue.length > 1 ? splitDataValue[1] : dataValue,
                tempo: ore + ':' + minuti,
                tipo: typeof commessa !== 'undefined' ? 'commessa' : 'causale',
                note,
                parent: null,
                smartworking
            };

            /*if (parent_id && parent_value) {
                data.parent = {
                    'id': parent_id,
                    'nome': parent_value
                };
            }*/
            if ((ore === 0 && Number(minuti) === 0) || ore > 23 || Number(minuti) > 59) {
                $('#modal-action .message').text('Rendicontazione non aggiunta, controllare il tempo.').removeClass('text-success').addClass('text-danger');
            } else if (!data.id) {
                $('#modal-action .message').text('Rendicontazione non aggiunta, selezionare una commessa oppure una causale.').removeClass('text-success').addClass('text-danger');
            } else {
                if (typeof this.giorni[day] == 'undefined') { this.giorni[day] = []; }
                this.giorni[day].push(data);
                $('#modal-action .message').text('Rendicontazione aggiunta.').removeClass('text-danger').addClass('text-success');
                return { data, render: this.render(day, data) };
            }
            return false;
        }


        if (formData['all-days'] && $('#rendicontazione .day.selected').length > 0) {
            const giorni_selezionati = $('#rendicontazione .day.selected');
            giorni_selezionati.each(function (index: number) {
                const day: number = Number($(giorni_selezionati[index]).attr('id')?.replace('d', ''));
                if (day) {
                    const add = addRendicontazione(formData, day);
                    if (add) { $('#giorno-' + day).append(add!.render); }
                }
            });
            $('#rendicontazione .day.selected').each(function (this: any) {
                $(this).removeClass('selected');
            })
        } else {
            const add = addRendicontazione(formData);
            if (add) {
                $('#giorno-' + day).append(add!.render);
            }
        }
        this.deleteParent(this.removeDay, this.setStatoModificaRendicontazione);
        this.updateTotateRendicontazione();
        this.setStatoModificaRendicontazione(1);
    }
}
export default Reportings;