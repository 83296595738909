import { AxiosResponse } from 'axios';
import { Person, Persons, PersonDevice, ISearchResult } from '../../helpers/interfaces/person';
import { Profilo } from '../../helpers/interfaces/user';
import { IDipendente } from '../../helpers/interfaces/magazzino/magazzino-home';
import axios from '../api';
const API_URL = process.env.REACT_APP_API_URL;
class PersonService {

  async getAll(personType = ''): Promise<Persons> {
    return await axios.get(API_URL + 'v2/administration/employees' + (personType !== '' ? ('/' + personType) : ''))
      .then((response: AxiosResponse<Persons>) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  async getAllDipendenti(): Promise<IDipendente[]> {
    return await axios.get<IDipendente[]>(API_URL + 'datore/dipendenti')
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  getAllOrganicoTabs(): Promise<{ [key: string]: string }> {
    return axios.get<{ [key: string]: string }>(API_URL + 'v2/administration/count/employees')
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  getAllForSearch(): Promise<ISearchResult> {
    return axios.get<ISearchResult>(API_URL + 'v2/administration/search/employees')
      .then(response => { return response.data; })
      .catch((error) => { return Promise.reject(); });
  }

  async add(data: any): Promise<Person> {
    return await axios.post(API_URL + 'datore/dipendente', {
      ...data
    })
      .then((response: AxiosResponse<Person>) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject();
      });
  }

  async getEmpoyee(id: number): Promise<Person & { error: any }> {
    return await axios.get(API_URL + 'datore/dipendente/' + id)
      .then((response: AxiosResponse<Person & { error: any }>) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject();
      });
  }

  async updateEmployee(employeeId: number, data: any): Promise<Person> {
    return await axios.put(API_URL + 'datore/person/' + employeeId + '/update', {
      ...data
    })
      .then((response: AxiosResponse<Person>) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject();
      });
  }

  async getProfile(): Promise<Profilo & { error: any }> {
    return await axios.get(API_URL + 'dipendente/profilo')
      .then((response: AxiosResponse<Profilo & { error: any }>) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject();
      });
  }

  async getDevices(id: number): Promise<PersonDevice & { error: any }> {
    return await axios.get(API_URL + '/magazzino/person/' + id + '/dispositivi')
      .then((response: AxiosResponse<PersonDevice & { error: any }>) => {
        return response.data;
      })
      .catch((error) => {
        return Promise.reject();
      });
  }

  updateProfileImage(id: number, data: FormData): Promise<{ filename: string, adminImage: string }> {
    return axios.put<FormData, AxiosResponse<{ filename: string, adminImage: string }>>(API_URL + 'datore/person/' + id + '/change-image', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    })
      .then(response => response.data)
      .catch(error => Promise.reject());
  }
}

export default new PersonService();
