import React, { Component } from 'react';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import TitoliStudioService from "../../../services/api/titolistudio.service";
import { getEditAnagraficaFormFields } from "./config/formFields";
import anagraficaService from '../../../services/api/anagrafica.service';
import EventBus from "../../../common/EventBus";
import Swal from 'sweetalert2';
import DatePickerForm from '../../../common/DatePickerForm';
import { FormFields, IFormField } from '../../../helpers/interfaces/generic';
import { buttonsStyle, iconsStyle } from '../../../helpers/settings/buttons-icons-styles';
import { PersonData } from '../../../helpers/interfaces/person';

type Props = {
  anagrafica: PersonData
};

type State = {
  data: any,
  loading: boolean,
  disabledForm: boolean,
  formFields: IFormField[],
  formInitialValues: any
};

class AnagraficaComponent extends Component<Props, State> {
  sesso: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      data: null,
      loading: false,
      disabledForm: true,
      formFields: [],
      formInitialValues: null
    }

    this.handleUpdate = this.handleUpdate.bind(this);
  }

  initFormValues(): any {
    const { anagrafica } = this.props
    return {
      cap_domicilio: anagrafica.cap_domicilio,
      cap_residenza: anagrafica.cap_residenza,
      cf: anagrafica.cf,
      codice_catastale_comune_nascita: anagrafica.codice_catastale_comune_nascita,
      comune_domicilio: anagrafica.comune_domicilio,
      comune_residenza: anagrafica.comune_residenza,
      data_nascita: anagrafica.data_nascita ? anagrafica.data_nascita : '',
      iban: anagrafica.iban,
      id: anagrafica.id,
      indirizzo_domicilio: anagrafica.indirizzo_domicilio,
      indirizzo_residenza: anagrafica.indirizzo_residenza,
      luogo_nascita: anagrafica.luogo_nascita,
      nazionalita: anagrafica.nazionalita,
      permesso_soggiorno: anagrafica.permesso_soggiorno,
      person_id: anagrafica.person_id,
      personal_email: anagrafica.personal_email,
      provincia_domicilio: anagrafica.provincia_domicilio,
      provincia_nascita: anagrafica.provincia_nascita,
      provincia_residenza: anagrafica.provincia_residenza,
      sesso: anagrafica.sesso ? anagrafica.sesso : '',
      telefono: anagrafica.telefono,
      titolistudio_id: anagrafica.titolistudio_id,
      titolo_studio_nome: anagrafica.titolo_studio_nome
    }
  }

  async componentDidMount() {
    EventBus.dispatch("showLoader", { text: 'Caricamento anagrafica in corso...' });

    const titoliStudio = await TitoliStudioService.getAll();

    let titolistudio: any = titoliStudio.map((item: any) => { return { key: item.id, value: item.nome } });
    titolistudio.unshift({ key: '', value: '---' })

    if (titoliStudio.length > 0) {
      this.setState({
        formFields: getEditAnagraficaFormFields(titolistudio),
        data: this.props.anagrafica,
        formInitialValues: this.initFormValues()
      });
    }

    EventBus.dispatch("hideLoader");
  }

  handleUpdate(formValue: any) {

    this.setState({
      loading: true
    });

    anagraficaService.update(this.props.anagrafica.person_id, formValue).then(
      () => {
        this.setState(
          {
            loading: false,
            disabledForm: true,
          },
          () => {
            Swal.fire({
              title: 'Anagrafica salvata.',
              icon: 'success',
            }).then(
              async res => {
                window.location.hash = "#anagrafica";
                window.location.reload();
              }
            );
          });
      },
      (error: any) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        this.setState(
          {
            loading: false,
          },
          () => {
            Swal.fire({
              title: 'Errore',
              text: resMessage,
              icon: 'error',
            });
          });
      }
    );
  }

  componentWillUnmount() {
    TitoliStudioService.abort();
  }

  validationSchema() {
    let validations: any = {};
    this.state.formFields.forEach((value: any, key: any) => (validations[value.name] = value.validation));

    return Yup.object().shape(validations);
  }

  render() {
    const { loading, disabledForm, formFields, formInitialValues } = this.state;

    formFields.map(field => field.value = formInitialValues[field.name])

    return <div>
      {
        formFields.length > 0 && <React.Fragment>

          <Formik
            initialValues={formInitialValues}
            validationSchema={this.validationSchema.bind(this)}
            onSubmit={this.handleUpdate}
          >
            {({ setFieldValue }) => (
              <Form className='px-3'>
                <div className="d-flex align-items-center justify-content-end">
                  <button style={buttonsStyle} className='btn rounded-circle' type='button' onClick={() => this.setState({ disabledForm: !disabledForm })} >
                    {
                      disabledForm ?
                        <i style={iconsStyle} className="fa fa-lock text-danger" aria-hidden="true"></i> :
                        <i style={iconsStyle} className="fa fa-unlock-alt text-success" aria-hidden="true"></i>
                    }
                  </button>
                </div>
                {
                  formFields.map((item: FormFields, key: number) => {
                    return <div className="mb-3" key={key}>
                      <label className="form-label">{item.label}: {item.required ? '*' : ''}</label>
                      {
                        item.type === 'select' ?
                          <Field as={item.type} name={item.name} className={item.class} disabled={disabledForm} required={item.required}>
                            {
                              item.values && item.values.map((item: any) => {
                                return <option key={item.key} value={item.key}>{item.value}</option>
                              })
                            }
                          </Field>
                          : (
                            item.type === 'date' ?
                              <DatePickerForm name={item.name} className={item.class} readValue={String(item.value)} disabled={disabledForm} onChange={(state) => setFieldValue(item.name, state.toSend)} />
                              : <Field name={item.name} type={item.type} className={item.class} disabled={disabledForm} required={item.required} />
                          )
                      }
                      <ErrorMessage
                        name={item.name}
                        component="div"
                        className="alert alert-danger"
                      />
                    </div>
                  })}

                <div className="d-flex justify-content-end">
                  <button type="submit" className="btn btn-primary" disabled={loading || disabledForm}>
                    {loading && (
                      <span className="spinner-border spinner-border-sm me-1"></span>
                    )}
                    <span>Salva</span>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </React.Fragment>}
    </div>
  }
}
export default AnagraficaComponent;